import { useLayoutEffect, useState } from 'react';
import _debounce from 'lodash.debounce';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

type Boundings = {
  width: number;
  height: number;
};
export function useSizeListener(componentRef) {
  const initializedBoundings: Boundings = {
    width: 50,
    height: 50,
  };
  const { isSSR } = useEnvironment();
  const [boundings, setBoundings] = useState(initializedBoundings);

  const setBoundingsRect = () => {
    const imageBoundings = componentRef?.current?.getBoundingClientRect();
    setBoundings(
      imageBoundings?.width && imageBoundings?.height
        ? imageBoundings
        : initializedBoundings,
    );
  };

  useLayoutEffect(() => {
    setBoundingsRect();
    !isSSR && window.addEventListener('resize', setBoundingsRect);
    return () => {
      if (!isSSR) {
        return window.removeEventListener('resize', setBoundingsRect);
      }
    };
  }, []);

  return componentRef?.current?.getBoundingClientRect() || boundings;
}
