import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

import settingsParams from '../settingsParams';

import { ISection, SectionTypes } from '../types';

export const useWidgetSections = (): ISection[] => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const sections: ISection[] = settings
    .get(settingsParams.sections)
    .filter((section) => section);
  if (sections.map(({ type }) => type).indexOf(SectionTypes.GALLERY) === -1) {
    sections.push({
      type: SectionTypes.GALLERY,
      visible: true,
      bookButton: false,
    });
  }

  if (isMobile) {
    const mobileSections = settings.get(settingsParams.mobileSections);
    if (mobileSections) {
      sections.sort(
        ({ type: firstSectionType }, { type: secondSectionType }) => {
          return (
            mobileSections.indexOf(firstSectionType) -
            mobileSections.indexOf(secondSectionType)
          );
        },
      );
    }
  }

  return sections;
};
