import React, { useRef } from 'react';
import * as imageSDK from '@wix/image-client-api/dist/imageClientSDK';
import { useEnvironment } from 'yoshi-flow-editor-runtime';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  HeroImage,
  FocalPointPresets,
  LoadingBehaviorOptions,
} from 'wix-ui-tpa/HeroImage';
import { HeaderViewModel } from '../../../../service-page-view-model/header-view-model/headerViewModel';
import { ImagePositionOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { st, classes } from './Header.st.css';
import { useSizeListener } from '../useSizeListener';
import settingsParams from '../../settingsParams';
import BookButton from '../BookButton/BookButton';
import { ImageDto } from '@wix/bookings-uou-types';
import { useDimensions } from '../useDimensions';

type HeaderProps = {
  viewModel: HeaderViewModel;
  className?: string;
  isRenderBackgroundURLOnSSR?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  viewModel,
  className,
  isRenderBackgroundURLOnSSR,
}) => {
  const settings = useSettings();
  const headerRef = useRef<HTMLDivElement>(null);
  const { isMobile, isRTL, isSSR } = useEnvironment();
  const imagePositionToFocalPoint = {
    [ImagePositionOptions.BOTTOM]: FocalPointPresets.bottomCenter,
    [ImagePositionOptions.BOTTOM_LEFT]: FocalPointPresets.bottomLeft,
    [ImagePositionOptions.BOTTOM_RIGHT]: FocalPointPresets.bottomRight,
    [ImagePositionOptions.LEFT]: FocalPointPresets.centerLeft,
    [ImagePositionOptions.RIGHT]: FocalPointPresets.centerRight,
    [ImagePositionOptions.TOP]: FocalPointPresets.centerTop,
    [ImagePositionOptions.TOP_LEFT]: FocalPointPresets.topLeft,
    [ImagePositionOptions.TOP_RIGHT]: FocalPointPresets.topRight,
    [ImagePositionOptions.MIDDLE]: FocalPointPresets.center,
  };

  const headerDimensions = useSizeListener(headerRef);
  const widgetDimensions = useDimensions();
  const getFillImageUrl = (media?: ImageDto) => {
    const targetWidth = media?.width;
    const targetHeight = media?.height;
    return media
      ? imageSDK.getScaleToFillImageURL(
          media.relativeUri,
          media.width,
          media.height,
          targetWidth,
          targetHeight,
        )
      : null;
  };

  const getImageContainerStyle = () => {
    return isRenderBackgroundURLOnSSR && isSSR && !viewModel?.isSEO
      ? {
          backgroundImage: `url(${getFillImageUrl(viewModel?.image)})`,
        }
      : {};
  };

  const shouldRenderImgElement = () => {
    const isSEO = viewModel?.isSEO;
    return !isSSR || (isSSR && isSEO);
  };

  const serviceImage = () => {
    return settings.get(settingsParams.headerImageVisibility) &&
      viewModel?.image ? (
      <div
        className={classes.media}
        data-hook={'header-background'}
        style={getImageContainerStyle()}
      >
        {widgetDimensions.width &&
        headerDimensions.height &&
        shouldRenderImgElement() ? (
          <HeroImage
            key={`${JSON.stringify(
              settings.get(settingsParams.headerImagePosition),
            )} ${viewModel?.image?.relativeUri} ${headerDimensions?.height} ${
              widgetDimensions.width
            }`}
            src={viewModel?.image?.relativeUri || ''}
            data-hook={'header-background-img'}
            width={
              isRenderBackgroundURLOnSSR && viewModel?.isSEO
                ? viewModel?.image?.width
                : widgetDimensions.width
            }
            height={
              isRenderBackgroundURLOnSSR && viewModel?.isSEO
                ? viewModel?.image?.height
                : headerDimensions?.height
            }
            alt={viewModel?.image?.altText}
            loadingBehavior={LoadingBehaviorOptions.blur}
            focalPoint={
              imagePositionToFocalPoint[
                settings.get(settingsParams.headerImagePosition)
              ]
            }
            className={classes.image}
          />
        ) : null}
      </div>
    ) : null;
  };

  const serviceTitle = () =>
    settings.get(settingsParams.headerTitleVisibility) ? (
      <Text
        data-hook="header-title"
        className={st(classes.title, { isRTL }, className)}
        typography={TYPOGRAPHY.largeTitle}
        tagName={settings.get(settingsParams.headerTitleHtmlTag)}
      >
        {viewModel?.title}
      </Text>
    ) : null;

  const bookButton = () =>
    settings.get(settingsParams.headerBookButtonVisibility) &&
    viewModel.isBookable ? (
      <BookButton dataHook="header-book-button" renderedAt="HEADER" />
    ) : null;

  return (
    <RenderLocationProvider value={MainComponents.HEADER}>
      <header
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.headerTitleAlignment),
            isMobile,
            isRTL,
          },
          className,
        )}
        aria-label={viewModel?.title}
        data-hook="header-wrapper"
        ref={headerRef}
      >
        {serviceImage()}
        {serviceTitle()}
        {bookButton()}
      </header>
    </RenderLocationProvider>
  );
};

export default Header;
